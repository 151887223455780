import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import Column from '../../common/Column'

const AboutTextContainer = styled('div')`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.black};
`
const AboutP = styled('p')`
  font-size: 0.75rem;
  margin: 0.25rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  color: ${props => props.theme.black};
`

class AboutText extends PureComponent {
  render() {
    return (
      <AboutTextContainer>
        <Column>
          <AboutP>
            The PATREC Planning Support System (PSS) demonstrates
            the application of academic research to better inform public 
            decision making. Using evidence-based modelling, future development 
            scenarios in terms of employment creation and public transport 
            patronage can be tested. These scenarios can be used to better 
            inform development of to maximise public transport patronage 
            and employment self-sufficiency. A cost-benefit analysis tool has 
            also been provided, which uses the Australian Transport Assessment 
            and Planning guidelines.
          </AboutP>
          <AboutP>
            The PSS was originally developed as RailSmart by a large team of 
            consultants and academics managed by PATREC for the City of 
            Wanneroo and the Department of Transport. It is a user-friendly 
            and modular system which can (and has) be further extended by future 
            research. Any questions surrounding the tool should be directed to&nbsp;
            <a href="mailto:tristan.reed@curtin.edu.au">Tristan Reed</a>. As 
            RailSmart, a&nbsp;
            <a href="https://resources.patrec.org/reports/railsmart/umdo.pdf">
            report</a> has been produced as an overview and user manual for the 
            PSS along with a&nbsp;
            <a href="https://onesearch.library.uwa.edu.au/discovery/dbfulldisplay?docid=alma99925459102101&context=L&vid=61UWA_INST:UWA&lang=en">
            formal citation</a>. If you have not used the PSS in a while, make 
            sure you review the&nbsp;
            <a href="https://patrec.org/railsmart-changelog">change log</a> to 
            understand the changes that have been made. The three components 
            that comprise the PSS are:
          </AboutP>
        </Column>
      </AboutTextContainer>
    )
  }
}

export default AboutText
