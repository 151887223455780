import React, { Component } from "react";
import { Alert, Button, Form, Icon, Input } from 'antd';
import { Auth } from "aws-amplify";

import { AuthenticationContainer, AuthenticationInnerContainer, 
    AuthenticationMap } from "./CommonComponents";

export default class Signup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            email: "",
            password: "",
            confirmPassword: "",
            confirmationCode: "",
            newUser: null,
            isAuthenticated: false,
            signupError: null
        };
    }

    validateForm() {
        return (
            this.state.email.length > 0 &&
            this.state.password.length > 0 &&
            this.state.password === this.state.confirmPassword
        );
    }

    validateConfirmationForm() {
        return this.state.confirmationCode.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            const newUser = await Auth.signUp({
                username: this.state.email,
                password: this.state.password
            });
            this.setState({
                newUser
            });
        } catch (e) {
            this.setState({signupError: e.message});
        }

        this.setState({ isLoading: false });
    }

    handleConfirmationSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
            // await Auth.signIn(this.state.email, this.state.password);
            // localStorage.setItem("isAuthenticated", "true")
            // this.state.userHasAuthenticated(true);
            // this.setState({isAuthenticated: true})
            this.props.history.push("/login");
        } catch (e) {
            this.setState({signupError: e.message});
            this.setState({isLoading: false});
        }
    }


    renderConfirmationForm() {
        const { signupError } = this.state;
        return (
            <>
                {(signupError != null) && (
                    <Alert message="An Error Occurred" description={signupError}
                        type="error" showIcon />
                )}
                {(signupError == null) && (
                     <Alert message="Verify with PATREC PSS" 
                        description={"Please check the email you specified in \
                        the form for a confirmation code to enter below."}
                        type="info" showIcon />
                )}
                <Form layout="vertical" onSubmit={this.handleConfirmationSubmit}>
                    <Input style={{margin: "1rem 0 0 0"}} prefix={<Icon type="mail"
                        style={{ color: 'rgba(0,0,0,.25)' }} />} size="large"
                        placeholder="Confirmation Code" id="confirmationCode" 
                        type="tel" value={this.state.confirmationCode} 
                        onChange={this.handleChange} />
                    <Button style={{margin: "1rem 0 0 0"}} type="primary" loading={this.state.isLoading}
                        size="large" htmlType="submit" block
                        disabled={!this.validateConfirmationForm()}
                        onClick={this.enterIconLoading}>Verify</Button>
                </Form>
            </>
        );
    }

    renderForm() {
        const { signupError } = this.state;
        return (
            <>
                {(signupError != null) && (
                    <Alert message="An Error Occurred" description={signupError} 
                        type="error" showIcon />
                )}
                {(signupError == null) && (
                    <Alert message="Sign Up to PATREC PSS" 
                        description={"Please fill in the form below to \
                        register for a free account with the PATREC PSS."}
                        type="info" showIcon />
                )}
                <Form layout="vertical" onSubmit={this.handleSubmit}>
                    <Input style={{margin: "1rem 0 0 0"}} prefix={<Icon type="mail"
                        style={{ color: 'rgba(0,0,0,.25)' }} />} size="large"
                        placeholder="Email Address" id="email" type="email"
                        value={this.state.email} onChange={this.handleChange} />
                    <Input style={{margin: "1rem 0 0 0"}} prefix={<Icon type="lock"
                        style={{ color: 'rgba(0,0,0,.25)' }} />} size="large"
                        placeholder="Password" id="password" type="password"
                        value={this.state.password} onChange={this.handleChange} />
                    <Input style={{margin: "1rem 0 0 0"}} prefix={<Icon type="lock"
                        style={{ color: 'rgba(0,0,0,.25)' }} />} size="large"
                        placeholder="Confirm Password" id="confirmPassword"
                        type="password" value={this.state.confirmPassword} 
                        onChange={this.handleChange} />
                    <Button style={{margin: "1rem 0 0 0"}} type="primary" loading={this.state.isLoading}
                        size="large" htmlType="submit" block
                        disabled={!this.validateForm()}
                        onClick={this.enterIconLoading}>Sign up</Button>
                </Form>
            </>
        );
    }

    render() {
        return (
            <AuthenticationContainer>
                <AuthenticationMap />
                <AuthenticationInnerContainer>
                    {this.state.newUser === null
                        ? this.renderForm()
                        : this.renderConfirmationForm()}
                </AuthenticationInnerContainer>
            </AuthenticationContainer>
        );
    }
}
