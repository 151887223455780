import React, { Component } from 'react'
import { DiscreteColorLegend, YAxis, XYPlot, LineSeries } from 'react-vis'
import styled from '@emotion/styled'

import ModelPanel from '../../hoc/ModelPanel'
import { ModelHeader, HeaderTooltip } from '../ModelContainer/CommonComponents'

const PassengerChurnContainer = styled('div')`
  flex: 1 1 auto;
  padding: 1rem 2rem 0 2rem;
`
const NoDataContainer = styled('div')`
  color: #b3b3b3;
  text-align: center;
  padding: 0.5rem;
`
const ChurnLegend = styled(DiscreteColorLegend)`
  padding: 0 0 2rem 0;

  & .rv-discrete-color-legend-item {
    padding: 0.25rem;
  }
`

class PassengerChurn extends Component {
    render() {
        const {data} = this.props;
        return (
            <PassengerChurnContainer>
            <>
              <ModelHeader heading={"Passenger Churn"} description={"This figure \
                shows the churn rates for passengers arriving at a hub. Rather \
                than a single snapshot, the graphs indicate the change in churn \
                rates over time. Churn is calculated for one reference year given \
                a reference month at the start of that year. For example, the \
                reference year start month of Nov 2016 is for churn relative to \
                the year Nov 2016 to Oct 2017. Acquired passengers first arrived \
                any time before Nov 2016 (since May 2015) and are still active \
                after Oct 2017. Short-term passengers are those first and last \
                arriving at this hub between Nov 2016 and Oct 2017 (inclusive). \
                Retained passengers were already active before Nov 2016 and \
                remain active after Oct 2017. Lost passengers were active before \
                Nov 2016 but their nal arrival at this hub is before Nov 2017. \
                The graph reports number of passengers counted by the number of \
                unique SmartRider cards. However, one person may have multiple \
                cards over the study period as a result of replacement cards or \
                new cards issued eg to tertiary students. The average number of \
                cards per person in the whole dataset is 1.25 over 4 years 8 \
                months. However, to protect passengers' privacy the trip log \
                data is only labelled by a hash of the card ID and does not link \
                cards to a passenger ID, so we must use the number of unique \
                cards as a proxy for the number of unique passengers. For these \
                reasons the number of passengers with short spans is slightly \
                overestimated in these graphs."} />
                  <HeaderTooltip sub={true} heading={data.area.SmartRider_StopName}
                    description={"A hub is a region in the \
                    network that attracts PT arrivals. Typical hubs comprise a \
                    railway station and the stops for connecting buses. \
                    Additionally there are bus-only hubs around attractors such \
                    as universities, hospitals, shopping centres and bus stations."} />
                  { data.churn.length > 0 && (
                    <>
                      <XYPlot width={250} height={250}>
                        <YAxis style={{fontSize: "6pt"}} />
                        { data.churn.map(l => <LineSeries key={l.series} 
                          data={l.data} color={l.color} /> ) }
                      </XYPlot>
                      <ChurnLegend items={data.churnItems} />
                    </>)}
                  { data.churn.length === 0 && (
                    <NoDataContainer>
                      No churn data for this Hub.
                    </NoDataContainer>)}
            </>
            <>
              <ModelHeader heading={"Disruptions"} description={"Each hub has a \
                time series with the sum of arrivals on each day of the year. \
                This view highlights seasonal and weekly changes as well as \
                disruptive events. Disruptions are sudden changes in demand \
                usually caused by a major external event. A short-term disruption \
                is indicated by a day with anomalous demand for that hub. We use \
                a simple test of -2 or +3 standard deviations from the mean daily \
                demand to identify exceptional days. Some short-term disruptions \
                occur on public holidays, and so are common to most hubs. There \
                are also hub-specic short-term disruptions caused by station \
                closures for maintenance or high demand for special events. The \
                table reports the number of days and calendar period of short \
                term disruptions. Long-term disruptions are caused by a major \
                change in land use such as closing or opening a park and ride \
                facility, relocation of large businesses (hospital or government \
                department) or the opening of a new train or bus station or major \
                new services or routes. Such disruptions are rare but they have \
                a large eect on patronage. Long-term disruptions are indicated \
                in arrivals data by a sudden rise or fall in demand at a \
                particular date. The table reports detected disruption(s) (if \
                any) and their date(s). Identifying disruptions is a work in \
                progress. Special events causing exceptionally high demand days \
                are not detected. For some hubs disruptions are confused with \
                holidays and weekend demand."} />
                  { data.daily.length > 0 && (
                    <>
                      <XYPlot width={250} height={250} yDomain={[0, 15000]}>
                        <YAxis style={{ fontSize: "6pt" }} />
                        <LineSeries series={"Daily Patronage"} data={data.daily[0].data} />
                      </XYPlot>
                    </>
                  )}
                  { data.daily.length === 0 && (
                    <NoDataContainer>
                      No daily data for this Hub.
                    </NoDataContainer>)}
              </>
            </PassengerChurnContainer>
        )
    }
}

export default ModelPanel(PassengerChurn, 'passenger churn')