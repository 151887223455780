import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Slider, Button, Icon, Select } from 'antd'
import { Analytics } from 'aws-amplify'

import MiniTitle from '../../common/MiniTitle'

const Option = Select.Option
const OptGroup = Select.OptGroup

const Container = styled('div')`
  padding: 1rem;
`
const SelectAttr = styled(Select)`
  padding-top: 0.75rem;
`
const AcademicContainer = styled('div')`
  margin: 0.5rem 0rem 0.5rem 0rem;
  width: 95%;
`
const YearInfo = styled('div')`
  margin-top: 0.5rem;
  font-size: 10pt;
`

export default class ContextLayers extends Component {

  constructor(props) {
    super(props)
    this.state = { patronageString: [null, null, null] }
  }

  async selectPatronage(idx, value) {
    this.state.patronageString[idx] = value;
    if ((this.state.patronageString[0] != null) && 
      (this.state.patronageString[1] != null) && 
      (this.state.patronageString[2] != null)) {
        var theString = this.state.patronageString.join('');
        this.chooseAttr("CrossOne", theString);
      }
  }

  async chooseAttr(field, value) {
    // Log Analytics Event
    Analytics.record({
      name: "ContextLayers-SelectAttr", attributes: {
        value: field + "-" + value
      }
    })
    const split_key = this.props.selectedLayer.split(';')
    await this.props.loadAttribute(split_key[0])
    await this.props.loadGeometry(split_key[1])
    await this.props.selectAttribute({ "value": value, "name": field })
  }

  async onChange(value) {
    // Log Analytics Event
    Analytics.record({
      name: "ContextLayers-SelectLayer", attributes: {
        value: value
      }
    })
    const split_key = value.split(';')
    await this.props.selectLayer(value)
    await this.props.loadAttribute(split_key[0])
    await this.props.loadGeometry(split_key[1])
  }

  render() {
    const {
      contextLayers,
      selectedLayer,
      dataLayer,
      selectedCrossOne,
      selectedCrossTwoA,
      selectedCrossTwoB,
      selectedTime
    } = this.props
    const contextGroups = contextLayers
      ? [...new Set(contextLayers.map(item => item.category))]
      : false

    return (
      <Container>
        <MiniTitle>Contextual Layers</MiniTitle>
        <Select
          style={{ width: 275 }}
          onChange={value => this.onChange(value)}
          placeholder={'Select a context layer'}
          value={selectedLayer ? selectedLayer : undefined}
        >
          <OptGroup key={'Turn off Layers'} label={'Turn off Layers'}>
            <Option key={'non;non'} value={'non;non'}>
              None
            </Option>
          </OptGroup>
          {contextGroups &&
            contextGroups.map(
              f =>
                f !== '' && (
                  <OptGroup key={f} label={f}>
                    {contextLayers.map(
                      g =>
                        g !== '' &&
                        g.category === f && (
                          <Option
                            key={g.attribute + ';' + g.geometry}
                            value={g.attribute + ';' + g.geometry}
                          >
                            {g.desc}
                          </Option>
                        )
                    )}
                  </OptGroup>
                )
            )}
        </Select>
        {dataLayer && 
          dataLayer.type === 'time' && (
          <>
            { ('choice' in dataLayer) && (
              <SelectAttr
                style={{ width: 275 }}
                onChange={value => this.chooseAttr("CrossOne", value)}
                value={selectedCrossOne ? selectedCrossOne : undefined}
                placeholder={'Select an attribute'}
              >
                {Object.keys(dataLayer.choice).map(k => (
                  <Option key={k} value={k}>
                    {dataLayer.choice[k]}
                  </Option>
                ))}
              </SelectAttr>
            )}
            { ('patronage' in dataLayer) && (
              <>
                <SelectAttr
                  onChange={value => this.selectPatronage(0, value)}
                  style={{ width: 275 }}
                  placeholder={'Select a ticket type'}>
                  <Option key={'std'} value={'std'}>Standard</Option>
                  <Option key={'con'} value={'con'}>Concession</Option>
                  <Option key={'snr'} value={'snr'}>Senior</Option>
                  <Option key={'stu'} value={'stu'}>Student</Option>
                </SelectAttr>
                <SelectAttr
                  onChange={value => this.selectPatronage(1, value)}
                  style={{ width: 275 }}
                  placeholder={'Select a time period'}>
                  <Option key={'wko'} value={'wko'}>Weekday, All-Day</Option>
                  <Option key={'weo'} value={'weo'}>Weekend, All-Day</Option>
                </SelectAttr>
                <SelectAttr
                  onChange={value => this.selectPatronage(2, value)}
                  style={{ width: 275 }}
                  placeholder={'Select a directionality'}>
                  <Option key={'or'} value={'or'}>Origin</Option>
                  <Option key={'de'} value={'de'}>Destination</Option>
                </SelectAttr>
              </>
            )}
            <YearInfo>Select the Year of Interest</YearInfo>
            <Slider min={dataLayer.fields[0]} 
              max={dataLayer.fields[dataLayer.fields.length - 1]}
              onChange={value => this.chooseAttr("time", value)}
              value={selectedTime ? selectedTime : dataLayer.fields[0]} 
              tipFormatter={value => "20" + value} />
          </>    
          )
        }
        {dataLayer &&
          dataLayer.type === 'CrossOne' && (
            <>
              <SelectAttr
                style={{ width: 275 }}
                onChange={value => this.chooseAttr("CrossOne", value)}
                value={selectedCrossOne ? selectedCrossOne : undefined}
                placeholder={'Select an attribute'}
              >
                {Object.keys(dataLayer.fields).map(f => (
                  <Option key={f} value={f}>
                    {dataLayer.fields[f]}
                  </Option>
                ))}
              </SelectAttr>
            </>
          )}
        {dataLayer &&
          dataLayer.type === 'CrossTwo' && (
            <>
              <SelectAttr
                style={{ width: 275 }}
                onChange={value => this.chooseAttr("CrossTwoA", value)}
                value={selectedCrossTwoA ? selectedCrossTwoA : undefined}
                placeholder={'Select an attribute'}
              >
                {Object.keys(dataLayer.fields[0]).map(f => (
                  <Option key={f} value={f}>
                    {dataLayer.fields[0][f]}
                  </Option>
                ))}
              </SelectAttr>
              <SelectAttr
                style={{ width: 275 }}
                onChange={value => this.chooseAttr("CrossTwoB", value)}
                value={selectedCrossTwoB ? selectedCrossTwoB : undefined}
                placeholder={'Select an attribute'}
              >
                {Object.keys(dataLayer.fields[1]).map(f => (
                  <Option key={f} value={f}>
                    {dataLayer.fields[1][f]}
                  </Option>
                ))}
              </SelectAttr>
            </>
          )}
        <AcademicContainer>
          <Button block style={{ fontSize: '0.75rem' }}
            href={"https://resources.patrec.org/reports/railsmart/gis.pdf"}>
            <Icon style={{ verticalAlign: '0.125em' }} type="file-text" />
            Download Academic Paper
          </Button>
        </AcademicContainer>
      </Container>
    )
  }
}
