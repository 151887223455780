import { createSelector } from 'reselect'

import { scaleThreshold } from 'd3-scale'
import classyBrew from 'classybrew'
import config from '../config/sa2Layer'
const stops = config['context']
const colors = stops.map(f => f[1])

const stylingSelector = state => state.context.styling
const layerSelector = state => state.context.selectedLayer
const activeFeature = state => state.context.selectedFeature

const attributeDataSelector = state => state.context.dataAttribute
const geometryDataSelector = state => state.context.dataGeometry
const contextLayerSelector = state => state.context.contextLayers

const crossOneSelector = state => state.context.selectedCrossOne
const crossTwoASelector = state => state.context.selectedCrossTwoA
const crossTwoBSelector = state => state.context.selectedCrossTwoB
const timeSelector = state => state.context.selectedTime
const columnSelector = state => state.context.selectedColumn
const newGeomSelector = state => state.context.newGeometry

export const selectorCombineAttribute = createSelector( timeSelector, 
  crossOneSelector, crossTwoASelector, crossTwoBSelector, contextLayerSelector, 
  layerSelector, columnSelector, attributeDataSelector, geometryDataSelector, 
  newGeomSelector, stylingSelector, 
  (time, crossOne, crossTwoA, crossTwoB, layersData, theLayer, column, 
  attribute, geometry, newGeometry, styling) => {

    // Prefill old value of Column.
    let theColumn = column;

    // Set up ContextLegend object.
    let contextLegend = {title: "", info: "", items: null}

    // Race conditions mean we have to recalculate this.
    if (layersData && theLayer) {

      // Pull out the selected layers' data.
      let layerData = layersData.filter(d => (d.geometry === theLayer.split(";")[1]) && 
        (d.attribute === theLayer.split(";")[0]));

      // Check the length of the above.
      if (layerData.length > 0) {

        // Set layerData to its first child.
        layerData = layerData[0];

        // Set title of Legend.
        contextLegend.title = layerData.desc;

        // Pull out the type of layer.
        let layerType = layerData.type;

        // Switch on the type of layerData. Label (i.e. annotate features).
        if (layerType === "Label") {

          // Check the length of the fields. 
          if (Object.keys(layerData.fields).length > 0) {

            // Grab the first key.
            let theKey = Object.keys(layerData.fields);

            // Set it to the value specified (first - @todo allow multiple).
            theColumn = theKey[0];

            // Set the Legend Description to the Value of the first Key.
            contextLegend.info = Object.values(layerData.fields)[0];
          }
        }

        // Single (i.e. choropleth with no options).
        if (layerType === "Single") {

          // Check the length of the fields. 
          if (Object.keys(layerData.fields).length > 0) {

            // Grab the first key.
            let theKey = Object.keys(layerData.fields);

            // Set it to the value specified.
            theColumn = theKey[0];

            // Set the Legend Description to the Value of the first Key.
            contextLegend.info = Object.values(layerData.fields)[0];
          }
        }

        // CrossOne (i.e. one set of options).
        if (layerType === "CrossOne") {

          // Ensure we have the selected value first.
          if (crossOne) {

            // Set it to the selected value.
            theColumn = crossOne;

            // Set the Legend Description to the Value of the first Key.
            contextLegend.info = layerData.fields[crossOne];
          }
        }

        // time (i.e. time only).
        if (layerType === "time") {

          // Ensure we have the selected value first.
          if (time) {

            // If we have crossOne for the popden.
            if (crossOne) {
              // Set it to the selected value.
              theColumn = crossOne + time;

              // Set the Legend Description to the Value of the first Key.
              contextLegend.info = layerData.legend;
            } else {
              // Set it to the selected value.
              theColumn = layerData.prefix + time;

              // Set the Legend Description to the Value of the first Key.
              contextLegend.info = layerData.legend;
            }
          }
        }

        // CrossTwo (i.e. two sets of options).
        if (layerType === "CrossTwo") {

          // Ensure we have the selected values first.
          if (crossTwoA && crossTwoB) {

              // Set it to the combination of the selected values.
              theColumn = crossTwoA + "_" + crossTwoB;

              // Set the Legend Description to the Value of the first Key.
              contextLegend.info = layerData.fields[0][crossTwoA] + ", " + 
                layerData.fields[1][crossTwoB];
          }
        }
      }
    
      // If the attribute value is null, then it is OK to set theColumn to null.
      if (theLayer.split(";")[0] === "null") {
        theColumn = null;
      }
    
    }

    if ((theColumn !== undefined) && attribute && geometry) {

      // Create the 'improved' copy of the geometry.
      newGeometry = true;

      // Iterate through each geographic feature.
      for (let iter_feature in geometry.features) {

        // Get the property/key for this one.
        let this_key = Object.keys(geometry.features[iter_feature].properties)[0];

        // Get that value as well.
        let this_value = Object.values(geometry.features[iter_feature].properties)[0];

        // Now, iterate through the attribute table. We'll filter to make it a 
        // bit quicker - I think? Check we have data first.
        if (!("code" in attribute)) {
        
          // Do the actual filtering.
          let this_attribute = attribute.filter(d => d[this_key] === this_value)

          // Check the length of that.
          if (this_attribute.length > 0) {

            // Pull out the first value
            let first_attribute = this_attribute[0];

            // Clear and shove the return into the feature.
            geometry.features[iter_feature].properties = {};
            geometry.features[iter_feature].properties[theColumn] = 
              parseFloat(first_attribute[theColumn]);

          }
        }
      }
    }

    if (geometry && attribute && newGeometry && theColumn) {

      let colorValues = geometry.features.filter(feature => 
        !isNaN(feature.properties[theColumn]));

      colorValues = colorValues.map(feature =>
        feature.properties[theColumn]);

      let brew = new classyBrew();
      brew.setSeries(colorValues);

      // Define scale to use it later.
      let scale = null;

      if (geometry.features[0].geometry.type === 'Polygon' ||
        geometry.features[0].geometry.type === 'MultiPolygon') {

        // Don't try and classify non-attribute data!
        if (!("code" in attribute)) {

          brew.setNumClasses(7);
          brew.classify('quantile');
          let classyBreaks = brew.getBreaks();

          // Hack to remove the useless first break.
          classyBreaks.shift(); 

          // Set up scale using outputs from classyBrew.
          scale = scaleThreshold().domain(classyBreaks).range(colors);

          // Set up 'items' bucket.
          let items = null;

          // Get legend breaks, if we can.
          if (!classyBreaks.includes(undefined)) {
            items = classyBreaks.map((f, i) => [parseFloat(f.toPrecision(4)), 
              colors[i]]);
          }

          // Set state of legend.
          contextLegend.items = items;
        }

        // Map features to colours.
        geometry.features = geometry.features.map(feature => {

          // Pull out the feature property.
          let joinedVal = +feature.properties[theColumn]

          // Set the default colour.
          let color = '#dddddd';

          // Set it to green if we are looking at the Parks and Reserves.
          if (contextLegend.title === "Parks and Reserves") {
            color = '#87A96B';
          }

          // If an actual value...
          if (!isNaN(joinedVal) && (scale !== null)) {

            // Set the real colour.
            color = scale(parseFloat(joinedVal));
          }

          // Rebuild the array.
          let properties = { color, [theColumn]: joinedVal }

          // Return to the caller.
          return { ...feature, properties }
        })
      }
    }

    // Return the changed and unchanged values.
    return { time: time, crossOne: crossOne, crossTwoA: crossTwoA, crossTwoB: crossTwoB, 
      column: theColumn, geometry: geometry, attribute: attribute, 
      newGeometry: newGeometry, styling: styling, contextLegend: contextLegend }
  }
)

export const selectorGetLayer = createSelector(
  layerSelector, contextLayerSelector, (layer, layers) => {

    // Set up the return value.
    var return_value = undefined

    // Ensure the pre-requisite values are set.
    if (layer && layers) {

      // Filter to bring back the selected layer.
      return_value = layers.filter(d => (d.geometry === layer.split(";")[1]) && 
      (d.attribute === layer.split(";")[0]))[0]
    }

    // Return the result.
    return return_value
  }
)

export const selectorPopupLayer = createSelector(
  activeFeature,
  (feature) => {
    return feature 
  }
)

export const selectorShowPopupLayer = createSelector(
  activeFeature,
  (selected)=>selected
)