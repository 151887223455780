import { Context } from '../constants/actionTypes'
import * as topojson from "topojson-client";

const { GET_METADATA, GET_METADATA_SUCCESS, GET_METADATA_FAILURE, SELECT_LAYER,
  GET_ATTRIBUTE, GET_ATTRIBUTE_SUCCESS, GET_ATTRIBUTE_FAILURE, GET_GEOMETRY,
  GET_GEOMETRY_SUCCESS, GET_GEOMETRY_FAILURE, SET_VALUE, SET_STYLING } = Context

export const initialState = {
  fetching: false,
  error: false,
  contextLayers: null,
  selectedLayer: null,
  selectedAttribute: null,
  selectedGeometry: null,
  dataAttribute: null,
  dataGeometry: null,
  dataLayer: null,
  selectedCrossOne: undefined,
  selectedCrossTwoA: undefined,
  selectedCrossTwoB: undefined,
  selectedTime: undefined,
  newGeometry: false,
  styling: null,
  contextLegend: null
}

export default function(state = initialState, { type, payload }) {
  switch (type) {

    case GET_METADATA: {
      return { ...state, fetching: true }
    }

    case GET_METADATA_SUCCESS: {
      return { ...state, fetching: false, error: false, 
        contextLayers: payload.layers }
    }

    case GET_METADATA_FAILURE: {
      return { ...state, fetching: false, error: true }
    }

    case SELECT_LAYER: {
      const split_values = payload.split(";");
      return { ...state, selectedLayer: payload, 
        selectedGeometry: split_values[1], selectedAttribute: split_values[0], 
        dataLayer: state.contextLayers, dataAttribute: undefined, 
        selectedCrossOne: undefined, selectedCrossTwoA: undefined, 
        selectedCrossTwoB: undefined, selectedTime: undefined }
    }

    case GET_ATTRIBUTE: {
      return { ...state, fetching: true }
    }

    case GET_ATTRIBUTE_SUCCESS: {
      return {
        ...state, fetching: false, error: false,
        dataAttribute: payload
      }
    }

    case GET_ATTRIBUTE_FAILURE: {
      return { ...state, fetching: false, error: true }
    }

    case GET_GEOMETRY: {
      return { ...state, fetching: true }
    }

    case GET_GEOMETRY_SUCCESS: {
      return {
        ...state, fetching: false, error: false,
        dataGeometry: topojson.feature(payload, Object.values(payload["objects"])[0])
      }
    }

    case GET_GEOMETRY_FAILURE: {
      return { ...state, fetching: false, error: true }
    }

    case SET_VALUE: {
      return { ...state, 
        selectedCrossOne: (payload.name === "CrossOne" ? payload.value : state.selectedCrossOne), 
        selectedCrossTwoA: (payload.name === "CrossTwoA" ? payload.value : state.selectedCrossTwoA),
        selectedCrossTwoB: (payload.name === "CrossTwoB" ? payload.value : state.selectedCrossTwoB),
        selectedTime: (payload.name === "time" ? payload.value : state.selectedTime)
      }
    }

    case SET_STYLING: {
      return { ...state, styling: payload }
    }

    default: {
      return state
    }

  }
}
