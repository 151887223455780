import React, { PureComponent } from 'react'
import { GeoJSONLayer, Popup } from 'react-mapbox-gl'
// import { PopupLayer } from "./Popup"

class VisualContextLayer extends PureComponent {
  state = {
    shouldOpen: false,
    coords: {"lng": null, "lat": null},
    featureValue: null
  }
  clickFeature = context => {
    if (this.state.shouldOpen === false) {
      this.setState({"shouldOpen": true});
      this.setState({"coords": context.lngLat});
      for (var key in context.features[0].properties) {
        if (key !== "color"){
          this.setState({"featureValue": context.features[0].properties[key]})
        }
      }
      // console.log(context.features[0]);
    } else {
      this.setState({"coords": context.lngLat});
      this.setState({"shouldOpen": false});
    }

  }
  render() {
    const { newGeometry, theGeometry, before, showMe } = this.props
    const { featureValue, shouldOpen, coords } = this.state
    return newGeometry === true && showMe? (
      <>
        {shouldOpen && (featureValue !== "null") && <Popup coordinates={coords}>{featureValue}</Popup>}
        <GeoJSONLayer
          id={'context'}
          data={theGeometry}
          before={before}
          fillOnClick={(context) => this.clickFeature(context)}
          linePaint={
            theGeometry &&
            theGeometry.features.length > 0 &&
            (theGeometry.features[0].geometry.type === 'LineString' ||
              theGeometry.features[0].geometry.type === 'MultiLineString')
              ? {
                  'line-color': '#333',
                  'line-opacity': 1,
                  'line-width': 2,
                }
              : {}
          }
          lineLayout={
            theGeometry &&
            theGeometry.features.length > 0 &&
            (theGeometry.features[0].geometry.type === 'LineString' ||
              theGeometry.features[0].geometry.type === 'MultiLineString')
              ? { visibility: 'visible' }
              : { visibility: 'none' }
          }
          fillPaint={
            theGeometry &&
            theGeometry.features.length > 0 &&
            (theGeometry.features[0].geometry.type === 'Polygon' ||
              theGeometry.features[0].geometry.type === 'MultiPolygon')
              ? {
                  'fill-color': ['get', 'color'],
                  'fill-opacity': 0.8,
                }
              : {}
          }
          fillLayout={
            theGeometry &&
            theGeometry.features.length > 0 &&
            (theGeometry.features[0].geometry.type === 'Polygon' ||
              theGeometry.features[0].geometry.type === 'MultiPolygon')
              ? {
                  visibility: 'visible',
                }
              : { visibility: 'none' }
          }
          circlePaint={
            theGeometry &&
            theGeometry.features.length > 0 &&
            theGeometry.features[0].geometry.type === 'Point'
              ? {
                  'circle-color': '#333',
                  'circle-radius': 3,
                }
              : {}
          }
          circleLayout={
            theGeometry &&
            theGeometry.features.length > 0 &&
            theGeometry.features[0].geometry.type === 'Point'
              ? { visibility: 'visible' }
              : { visibility: 'none' }
          }
        />
      </>
    ) : null
  }
}

export default VisualContextLayer
