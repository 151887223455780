import React from 'react'
import styled from '@emotion/styled'

const Awards = styled('div')`
  text-align: center;
  padding: 0.75rem;
  padding-top: 0.25rem;
`

const AboutBottom = () => {
  return (
    <Awards />
  )
}

export default AboutBottom;