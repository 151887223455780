import { ActivityTrend } from '../constants/actionTypes'
import { queryActivityTrend } from '../queries/activitytrend'

const {
    GET_ACTTREND,
    GET_ACTTREND_SUCCESS,
    GET_ACTTREND_FAILURE,
} = ActivityTrend

export const loadActivityTrend = () => {
    return function (dispatch) {
        dispatch(getActivityTrend())
        queryActivityTrend()
            .then(res => res.json())
            .then(json => dispatch(getActivityTrendSuccess(json)))
            .catch(err => dispatch(getActivityTrendFailure(err)))
    }
}

export const getActivityTrend = () => ({
    type: GET_ACTTREND,
})

export const getActivityTrendSuccess = payload => ({
    type: GET_ACTTREND_SUCCESS,
    payload,
})

export const getActivityTrendFailure = payload => ({
    type: GET_ACTTREND_FAILURE,
    payload,
})
