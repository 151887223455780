import { ActivityTrend } from '../constants/actionTypes'

const {
    GET_ACTTREND,
    GET_ACTTREND_SUCCESS,
    GET_ACTTREND_FAILURE,
} = ActivityTrend

export const initialState = {
    results: [],
    fetching: false,
    error: null,
}

export default function (state = initialState, { type, payload }) {
    switch (type) {

        case GET_ACTTREND: {
            return {
                ...state,
                fetching: true,
            }
        }

        case GET_ACTTREND_SUCCESS: {
            return {
                ...state,
                results: payload,
                fetching: false,
            }
        }

        case GET_ACTTREND_FAILURE: {
            return {
                ...state,
                fetching: false,
                error: payload,
            }
        }
        default:
            return state
    }
}
