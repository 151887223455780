import React, { Component } from 'react'
import { DiscreteColorLegend, YAxis, XYPlot, LineSeries } from 'react-vis'
import styled from '@emotion/styled'

import ModelPanel from '../../hoc/ModelPanel'
import { ModelHeader } from '../ModelContainer/CommonComponents'

const ActivityTrendContainer = styled('div')`
  flex: 1 1 auto;
  padding: 1rem 2rem 0 2rem;
`
const NoDataContainer = styled('div')`
  color: #b3b3b3;
  text-align: center;
  padding: 0.5rem;
`
const ThisLegend = styled(DiscreteColorLegend)`
  display: block;
  padding: 0 0 2rem 0;

  & .rv-discrete-color-legend-item {
    padding: 0.25rem;
  }
`
const ATSHContainer = styled('div')`
  font-size: 1.25rem;
  font-weight: bold;
  padding-bottom: 1rem;
`

class ActivityTrend extends Component {
    render() {
        const {data} = this.props;
        return (
            <ActivityTrendContainer>
                <>
                  <ATSHContainer>{ data.area }</ATSHContainer>
                </>
                <>
                  <ModelHeader heading={"Activity Trends"} description={"Each graph shows the time series trends for per week demand after noise and \
                    seasonality is removed. The trend series cover for 190 weeks from \
                    2016 to 2019. Seasonally adjusted trends are calculated for different \
                    types of activities each selection of arrivals at the hub. All \
                    arrivals are partitioned in three ways: roles, tokens and activities. Role trends \
                    partition arrivals into generator, attractor and transfer activities."} />
                  { data.cag.length > 0 && (
                    <>
                      <XYPlot width={250} height={250} yDomain={[0, 17500]}>
                        <YAxis style={{ fontSize: "6pt" }} />
                        {data.cag.map(l => <LineSeries key={l.series}
                          data={l.data} color={l.color} />)}
                      </XYPlot>
                      <ThisLegend items={data.cagItems} />
                    </>)}
                  { data.cag.length === 0 && (
                    <NoDataContainer>
                      No CAG data for this Hub.
                    </NoDataContainer>)}
                </>
                <>
                  <ModelHeader heading={"Ticket Type"} description={"Token trends partition arrivals by the ticket type used: standard, \
                    school, tertiary or concession (encompassing all other ticket \
                    types including seniors)."} />
                  { data.tix.length > 0 && (
                    <>
                      <XYPlot width={250} height={250} yDomain={[2500, 15000]}>
                        <YAxis style={{ fontSize: "6pt" }} />
                        {data.tix.map(l => <LineSeries key={l.series}
                          data={l.data} color={l.color} />)}
                      </XYPlot>
                      <ThisLegend items={data.tixItems} />
                    </>)}
                  { data.tix.length === 0 && (
                    <NoDataContainer>
                      No Ticket Type data for this Hub.
                    </NoDataContainer>)}
                </>
                <>
              <ModelHeader heading={"Stay Type"} description={"Activity trends partition arrivals based on the \
                    type of activity undertaken at the hub. Activities are a data-driven \
                    model similar to a trip purpose where the time of arrival and length \
                    of time spent at the hub are used to group arrivals. For example, \
                    arrival at 9am and staying for 8 to 10 hours is labelled as a \"going \
                    to work\" trip purpose, and arriving after 3pm and staying overnight is \
                    a \"sleep\" activity. The 12 activity types offer a more detailed \
                    view of the three role activities. Trends partitioned by activity \
                    type are important for understanding passenger demand because they \
                    highlight which types of activities have fallen off or grown, \
                    giving insight into the possible causes of changes in patronage."}/>
                  { data.type.length > 0 && (
                    <>
                      <XYPlot width={250} height={250} yDomain={[0, 7500]}>
                        <YAxis style={{ fontSize: "6pt" }} />
                        {data.type.map(l => <LineSeries key={l.series}
                          data={l.data} color={l.color} />)}
                      </XYPlot>
                      <ThisLegend items={data.typeItems} />
                    </>)}
                  { data.type.length === 0 && (
                    <NoDataContainer>
                      No Stay Type data for this Hub.
                    </NoDataContainer>)}
                </>
            </ActivityTrendContainer>
        )
    }
}

export default ModelPanel(ActivityTrend, 'activity trend')