import React, { Component } from 'react'
import styled from '@emotion/styled'

import Search from '../../containers/Search'

const SearchContainer = styled('div')`
  padding-left: 9px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

class ContextSearch extends Component {
  render() {
    return (
      <SearchContainer>
        <Search 
          border={false}
          size={'small'}
          title={'Search station or stop'}
        />
      </SearchContainer>
    )
  }
}

export default ContextSearch
