import { connect } from 'react-redux'
import ActivityTrend from '../components/ActivityTrend'
import selectorActivityTrendData from '../selectors/activitytrend-selector'

export default connect(
    state => {
        return {
            data: selectorActivityTrendData(state),
        }
    }
)(ActivityTrend)