import { Loading } from '../constants/actionTypes'
import { loadSA2s } from './sa2layer'
import { loadActivities } from './behaviour'
import { loadAllHubPatronage } from './hub'
import { loadEmployment } from './employment'
import { loadNodeplace, loadClusters } from './nodeplace'
import { loadTravelSurvey } from './travelsurvey'
import { loadActivityTrend } from './activitytrend'
import { loadPassengerChurn } from './passengerchurn'
import { loadPopulation } from './population'
import { loadStructurePlans, loadStnBuffers, loadWorkingpop } from './scenarioplanner'
import { loadLGAs } from './lgalayer';
import { loadMetadata} from "./context";

const { SET_LOADING } = Loading

export const loadAllInitData = () => {
  return dispatch => {
    dispatch(setLoading(true))
    Promise.all([
      dispatch(loadSA2s()),
      dispatch(loadActivities()),
      dispatch(loadAllHubPatronage()),
      dispatch(loadEmployment()),
      dispatch(loadNodeplace()),
      dispatch(loadClusters()),
      dispatch(loadMetadata()),
      dispatch(loadTravelSurvey()),
      dispatch(loadActivityTrend()),
      dispatch(loadPassengerChurn()),
      dispatch(loadPopulation()),
      dispatch(loadStructurePlans()),
      dispatch(loadStnBuffers()),
      dispatch(loadWorkingpop()),
      dispatch(loadLGAs())
    ]).then(() => dispatch(setLoading(false)))
  }
}

export const setLoading = payload => ({
  type: SET_LOADING,
  payload,
})
