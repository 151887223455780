import React from 'react'
import styled from '@emotion/styled'
import { Radio, Tooltip } from 'antd'
import RadioButton from '../../common/RadioButton'
import MiniTitle from '../../common/MiniTitle'
import IconButtonMini from '../../common/IconButtonMini'

const Container = styled('div')`
  padding: 1rem 0;
`
const RadioGroupContainer = styled(Radio.Group)`
  display: flex;
`
const MiniTitleContainer = styled('div')`
    display: flex;
    flex-direction: row;
`

const lqText = (<>
  A location quotient (LQ) is a valuable way of quantifying how concentrated a
  particular industry is in comparison to the city, state or country.
  It can reveal what makes particular regions ‘unique’ in comparison to the
  average. A LQ of 1 means the area is exactly average, below 1 indicates
  that the area is not competitive or is a net importer of that sector.Any
  area above 1 indicates a competitive advantage.
</>);

const LocationComparisonAreas = ({ onChange, options, selected }) => {
  const change = e =>
    onChange({ key: 'selectedComparisonArea', value: e.target.value })
  return (
    <Container>
      <MiniTitleContainer>
        <MiniTitle>Location quotient comparison area</MiniTitle>
        <Tooltip placement={"right"} title={lqText}>
          <IconButtonMini shape={'circle'} icon="info-circle"
            size={'small'} style={{ marginTop: '-6px'}} />
        </Tooltip>
      </MiniTitleContainer>
      <RadioGroupContainer value={selected} onChange={change}>
        {options.map(f => (
          <RadioButton key={f} value={f}>
            {f}
          </RadioButton>
        ))}
      </RadioGroupContainer>
    </Container>
  )
}

export default LocationComparisonAreas
