import React, { Component } from "react";
import { Alert, Button, Form, Icon, Input } from 'antd';
import { Auth } from 'aws-amplify';

import { AuthenticationContainer, AuthenticationInnerContainer, 
    AuthenticationMap } from "./CommonComponents";

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: this.props.isAuthenticated,
            userHasAuthenticated: this.props.userHasAuthenticated,
            handleLogout: this.props.handleLogout,
            isLoading: false,
            email: "",
            password: "",
            loginError: null
        };

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {

        return true;
    }

    componentWillMount() {

        // a real hack to get the navigation to change state on redirect here
        window.currentUser.setState({isAuthenticated: false} );
    }

    componentDidMount () {

    }

    validateForm() {

        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }


    handleSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            await Auth.signIn(this.state.email, this.state.password);

            localStorage.setItem("isAuthenticated", "true")

            // this.state.userHasAuthenticated(true);
            this.setState({ isAuthenticated: true})

            this.props.history.push("/");
            window.location = "/"
        } catch (e) {
            this.setState({loginError: e.message})
            this.setState({ isLoading: false });
        }
    }


    render() {

        const { loginError } = this.state;

        return (
            <AuthenticationContainer>
                <AuthenticationMap />
                <AuthenticationInnerContainer>
                    {(loginError != null ) && (
                        <Alert message="An Error Occurred" 
                            description={loginError} type="error" showIcon />
                    )}
                    {(loginError == null) && (
                        <Alert message="Welcome to the PATREC PSS" 
                            description={"Please log in with your credentials \
                            below. Please click 'Sign Up' above to gain access \
                            to the PATREC PSS for free."} type="info" 
                            showIcon />
                    )}
                    <Form layout="vertical" onSubmit={this.handleSubmit}>
                        <Input style={{margin: "1rem 0 0 0"}} prefix={<Icon type="mail" 
                            style={{ color: 'rgba(0,0,0,.25)' }} />} size="large" 
                            placeholder="Email Address" id="email" type="email"
                            value={this.state.email} onChange={this.handleChange} />
                        <Input style={{margin: "1rem 0 0 0"}} prefix={<Icon type="lock" 
                            style={{ color: 'rgba(0,0,0,.25)' }} />} size="large" 
                            placeholder="Password" id="password" type="password"
                            value={this.state.password} onChange={this.handleChange} />

                        <Button style={{margin: "1rem 0 0 0"}} type="primary" 
                            loading={this.state.isLoading} 
                            size="large" htmlType="submit" block
                            disabled={!this.validateForm()}
                            onClick={this.enterIconLoading}>Log in</Button>
                    </Form>
                </AuthenticationInnerContainer>
            </AuthenticationContainer>
        );
    }
}
