import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Select } from 'antd'

import MiniTitle from '../../common/MiniTitle'
import EmploymentControls from '../../containers/EmploymentControls'
import ContextLayerControls from '../../containers/ContextLayerControls'

const Option = Select.Option

const Container = styled('div')`
    padding: 1rem;
`

const LayerControl = styled('div')`
    padding: 0 1rem;
`

class ContextLayers extends Component {

    setMode = e => this.props.setMode(e)

    render() {
        const { modes, mode } = this.props 
        return (
            <Container>
                <LayerControl>
                    <MiniTitle>Layer Groups</MiniTitle>
                    <Select name={'layer control'} 
                        style={{ width: 275 }}
                        onChange={this.setMode} 
                        placeholder={'Select a Layer Group'}
                        value={mode}>
                        { modes.map(f=> <Option key={f} value={f}>{f} Layers</Option>) }
                    </Select>
                </LayerControl>
                {
                    mode === 'Employment' && <EmploymentControls setContextLegendData={this.props.setContextLegendData}/>
                }
                {
                    mode === 'Context' && <ContextLayerControls setContextLegendData={this.props.setContextLegendData}/>
                }
            </Container>
        )
    }
}

export default ContextLayers