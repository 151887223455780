import { createSelector } from 'reselect'

export const passengerChurnSelector = state => state.passengerChurn.results

const selectedHubSelector = state => state.hubs.selectedHub

export const selectorPassengerChurnData = createSelector(
    passengerChurnSelector, selectedHubSelector, (data, area) => {

        let colorList = { "Acquired": "rgb(179, 222, 105)", 
            "Short-term": "rgb(190, 186, 218)", 
            "Retained": "rgb(252, 205, 229)", 
            "Lost": "rgb(251, 128, 114)"};

        var returnChurn = [];
        var churnItems = [];
        var filteredChurn = data.churn.filter(d => d.Hub === area.SmartRider_StopName);

        var returnDaily = [];
        var filteredDaily = data.daily.filter(d => d.index === area.SmartRider_StopName);
        
        filteredChurn.forEach((each_row) => {
            var thisDict = {"series": null, "hub": null, "data": []};
            var fcCount = 0;
            Object.entries(each_row).forEach(([key, value]) => {
                if (key === "ChurnClass") {
                    thisDict["series"] = value;
                    thisDict["color"] = colorList[value];
                    churnItems.push({"title": value, 
                        "color": colorList[value]});
                } else if (key === "Hub") {
                    thisDict["hub"] = value;
                } else {
                    thisDict["data"].push({"x": fcCount, "y": value});
                    fcCount++;
                }
            });
            returnChurn.push(thisDict);
        });

        filteredDaily.forEach((each_row) => {
            var thisDict = {"data": []};
            var dCount = 0;
            Object.entries(each_row).forEach(([key, value]) => {
                if (key !== "index") {
                    thisDict["data"].push({"x": dCount, "y": value});
                    dCount++;
                }
            });
            returnDaily.push(thisDict);
        });

        return {"churn": returnChurn, "churnItems": churnItems, "area": area, 
            "daily": returnDaily};
    }
)

export default selectorPassengerChurnData;