import React from 'react'
import styled from '@emotion/styled'

import AboutText from './AboutText'

const Container = styled('div')`
    padding: 1.5rem;
    padding-bottom: 1rem;
`
const Heading = styled('div')`
    font-size: 1.5rem;
    font-weight: 300;
    padding-bottom: 0.5rem;
`
const AboutTitle = () => {

    return (
        <Container>
            <Heading>
                Welcome to the PATREC Planning Support System Dashboard
            </Heading>
            <AboutText />
        </Container>
    )
}

export default AboutTitle