import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Icon, Input, AutoComplete, Tooltip } from 'antd'
import { debounce } from 'debounce'

const Option = AutoComplete.Option

const AutoCompleteContainer = styled(AutoComplete)`
  &.ant-select-auto-complete.ant-select .ant-input {
    border: ${props => (props.border ? '1px solid #d9d9d9' : 'none')};
  }
  & .ant-select-selection__placeholder {
    color: ${props => props.theme.black};
    left: 25px;
  }
`

const SearchContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 210px;
  overflow: hidden;
`
const IconContainer = styled(Icon)`
  color: ${props => props.theme.slightlydarkergrey};
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 16px;
`

class Search extends PureComponent {
  onSelect = value => this.props.selectHub(parseInt(value, 10))
  resetBox = value => this.props.selectHub("")

  render() {
    const {
      border,
      title,
      size,
      searchterm,
      allresults,
      fetching,
      hub,
    } = this.props
    const children = allresults
      ? allresults.length > 0 &&
        allresults.map(result => {
          return (
            <Option key={result.ID}>
              {result.SmartRider_StopName
                ? result.SmartRider_StopName
                : result.NodePlace_Station}
            </Option>
          )
        })
      : null

    return (
      <SearchContainer>
        <AutoCompleteContainer
          border={border}
          key={hub}
          style={{ width: 210 }}
          onSelect={this.onSelect}
          onSearch={debounce(e => this.props.handleSearch(e), 600)}
          size={size}
          dataSource={children}
          placeholder={!fetching && searchterm}
        >
          <Input
            style={{paddingLeft: '8px'}}
            key={hub}
            placeholder={searchterm ? null : title}
            prefix={<IconContainer type="search" />}
            value={searchterm}
          />
        </AutoCompleteContainer>
        {(!fetching && searchterm) && 
          (<Tooltip placement={"bottom"} title={"Clear Search"}>
            <IconContainer
              onClick={this.resetBox}
              style={{ color: 'white', marginLeft: '8px', marginBottom: '1px' }}
              type="close"
            />
          </Tooltip>)}
      </SearchContainer>
    )
  }
}

Search.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  selectHub: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
}
export default Search
