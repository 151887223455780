import React, { Component } from 'react'
import styled from '@emotion/styled'
import { FaLayerGroup, FaMapSigns } from "react-icons/fa"
import { Tooltip } from "antd"

import ContextLayers from '../ContextLayers'
import Legend from '../ContextLayers/Legend'
import ContextSearch from '../ContextLayers/ContextSearch'

import Column from '../../common/Column'
import Row from '../../common/Row'

const Container = styled(Column)`
  position: absolute;
  top: 100px;
  width: 340px;
  left: 1.5rem;
  z-index: 3;
  border-radius: 3px;
  background-color: ${props => props.theme.lightgrey};
  box-shadow: 0px 2px 4px 0px ${props => props.theme.slightlydarkergrey};
`
const HeaderContainer = styled(Column)`
  box-shadow: 0px 2px 4px 0px ${props => props.theme.slightlydarkergrey};
  border-radius: 3px;
  align-items: flex-start;
`
const Heading = styled(Row)`
  padding: 0.5rem;
  border-radius: 3px 3px 3px 3px;
  background-color:${props=>props.theme.darkbluegrey};
  color:${props=>props.theme.white};
  align-items: center;
`
const ToolContainer = styled(Row)`
  padding: 0.5rem 0 0.5rem 0.5rem;
  width: 100px;
  align-items: center;
`
const Tool = styled('div')`
  font-size: 16px;
  cursor: pointer;
  margin-right: 0.25rem;
  padding-right: 0.25rem;
  font-weight: ${props => props.weight};
`

class MapToolbox extends Component {

  state = {
    tools: ['Layers', 'Legend'],
    selectedTool: '',
    open: true,
    legendData: false
  }

  updateTab = selectedTool => {
    if (selectedTool === this.state.selectedTool) {
      this.setState({selectedTool: ""})
      this.setState({open: false})
    } else {
      this.setState({ selectedTool })
      this.setState({open: true})    
    }
  }

  // for the search bar
  clearSelectedHub = () => this.props.selectHub(null)

  // sets the legend data 
  setContextLegendData = legendData => this.setState({ legendData })

  // sets the layer mode
  setMode = selectedMode => this.props.setMode(selectedMode)

  render() {
    const { tools, selectedTool, open, legendData } = this.state
    const { modelName, contextLegend, modes, selectedMode, showContextLayer, showEmploymentLayer } = this.props
    return (
      <Container>
        <HeaderContainer>
          <Heading>
            <ToolContainer>
              {tools.map(tool => {
                return (
                  <Tool
                    key={tool}
                    style={{"color": (tool === selectedTool ? '#ffffff' : '#7f8c9f')}}
                    onClick={() => this.updateTab(tool)}
                  >
                    <Tooltip placement={"bottom"} title={tool}>
                      {(tool === "Layers") && <FaLayerGroup />}
                      {(tool === "Legend") && <FaMapSigns />}
                    </Tooltip>
                  </Tool>
                )
              })}
            </ToolContainer>
            <ContextSearch clear={this.clearSelectedHub} />
          </Heading>
        </HeaderContainer>
        <Column>
          {open &&
            selectedTool === 'Layers' && (
              <ContextLayers 
                modes={modes}
                mode={selectedMode}
                setMode={this.setMode}
                setContextLegendData={this.setContextLegendData} 
              />
            )}
          {open &&
            selectedTool === 'Legend' && (
              <Legend 
                showContextLayer={showContextLayer}
                showEmploymentLayer={showEmploymentLayer}
                mode={selectedMode}
                contextLegend={contextLegend} 
                modelName={modelName} 
                legendData={legendData} 
              />
            )}
        </Column>
      </Container>
    )
  }
}

export default MapToolbox
