import { PassengerChurn } from '../constants/actionTypes'

const {
    GET_PASSCHURN,
    GET_PASSCHURN_SUCCESS,
    GET_PASSCHURN_FAILURE,
} = PassengerChurn

export const initialState = {
    results: [],
    fetching: false,
    error: null,
}

export default function (state = initialState, { type, payload }) {
    switch (type) {

        case GET_PASSCHURN: {
            return {
                ...state,
                fetching: true,
            }
        }

        case GET_PASSCHURN_SUCCESS: {
            return {
                ...state,
                results: payload,
                fetching: false,
            }
        }

        case GET_PASSCHURN_FAILURE: {
            return {
                ...state,
                fetching: false,
                error: payload,
            }
        }
        default:
            return state
    }
}
