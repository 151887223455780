import {
    connect
} from 'react-redux'

import ContextLayerControls from '../components/ContextLayers/ContextLayerControls'

import {
    selectLayer,
    selectAttribute,
    loadAttribute,
    loadGeometry
} from '../actions/context'

import {
    selectorGetLayer,
    selectorCombineAttribute
} from '../selectors/contextLayer-selector'

export default connect(
    state => {
        return {
            modelName: state.sa2layer.modelName,
            contextLayers: state.context.contextLayers,
            selectedLayer: state.context.selectedLayer,
            dataLayer: selectorGetLayer(state),
            selectedCrossOne: selectorCombineAttribute(state).crossOne,
            selectedCrossTwoA: selectorCombineAttribute(state).crossTwoA,
            selectedCrossTwoB: selectorCombineAttribute(state).crossTwoB,
            selectedTime: selectorCombineAttribute(state).time,
        }
    }, {
        selectLayer,
        selectAttribute,
        loadAttribute,
        loadGeometry
    }
)(ContextLayerControls)