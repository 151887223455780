import React, { Component } from "react";
import styled from '@emotion/styled';

const AttributionText = styled('div')`
  z-index: 3;
  font-size: 0.625rem;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 0.5rem;
  color: #000000;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  text-align: center;
  line-height: 0.875rem;
`
const AttributionDivider = styled('div')`
  border-bottom: 1px solid #333333;
  padding-top: 0.25rem;
  margin-bottom: 0.25rem;
`

export class Attribution extends Component {
  render() {
    return (
      <AttributionText>
        Basemap data &copy; <a href="https://openstreetmap.org/copyright">
        OpenStreetMap Contributors</a>.
        <AttributionDivider />
        Use of the PATREC PSS is in accordance with the <br />
        <a href="https://resources.patrec.org/legal/railsmart/tos.html">
        Terms of Service</a> and&nbsp;
        <a href="https://resources.patrec.org/legal/railsmart/privacy.html">
        Privacy Policy</a>.
      </AttributionText>
    )
  }
}

export default Attribution;