import { connect } from 'react-redux'
import PassengerChurn from '../components/PassengerChurn'
import selectorPassengerChurnData from '../selectors/passengerchurn-selector'

export default connect(
    state => {
        return {
            data: selectorPassengerChurnData(state),
        }
    }
)(PassengerChurn)