import { PassengerChurn } from '../constants/actionTypes'
import { queryPassengerChurn } from '../queries/passengerchurn'

const {
    GET_PASSCHURN,
    GET_PASSCHURN_SUCCESS,
    GET_PASSCHURN_FAILURE,
} = PassengerChurn

export const loadPassengerChurn = () => {
    return function (dispatch) {
        dispatch(getPassengerChurn())
        queryPassengerChurn()
            .then(res => res.json())
            .then(json => dispatch(getPassengerChurnSuccess(json)))
            .catch(err => dispatch(getPassengerChurnFailure(err)))
    }
}

export const getPassengerChurn = () => ({
    type: GET_PASSCHURN,
})

export const getPassengerChurnSuccess = payload => ({
    type: GET_PASSCHURN_SUCCESS,
    payload,
})

export const getPassengerChurnFailure = payload => ({
    type: GET_PASSCHURN_FAILURE,
    payload,
})
