import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Icon, Collapse } from 'antd'

import Row from '../../../common/Row'
import Column from '../../../common/Column'
import MultiplierCalc from './Multiplier'
import SectorPicker from './SectorPicker'
import NewEmp from './NewEmp'
import { Steps, Tooltip } from 'antd'
import LeftPanel from './LeftPanel'
import CollapseHeader from '../CollapseHeader'
import PrimaryStepper from '../../../common/PrimaryStepper'

const Panel = Collapse.Panel

const InfoText = styled('div')`
  font-size: 12px;
  font-weight: normal;
  color: ${props => props.theme.black};
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 20px;
`

const Container = styled('div')`
  margin: -16px;
`

const UnshadedPanel = styled(Column)`
  padding: 16px;
  width: 100%;
`

const ResetScenario = styled(Row)`
  justify-content: space-between;
  align-items: center;
  width: 120px;
  color: #149fd7;
  cursor: pointer;
  margin-bottom: 20px;
`

const ColorWrapper = styled('div')`
  color: #149fd7;
  font-size: 25px;
  font-weight: normal;
`

const CenteredRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
`

const Step = styled(Steps.Step)`
  cursor: pointer;
`

const StepsContainer = styled(PrimaryStepper)`
  margin-bottom: 1rem;
  width: 100%;
`

const InfoIcon = styled(Icon)`
  color: ${props => props.theme.darkgrey};
`

export default class EmploymentCalculator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newPop: 433000,
      workingAgePop: 60,
      existingJobs: 150000,
      selfSufficiencyTarget: 60,
      selectedIndustries: [],
      newEmployment: {},
      newEmploymentAdded: {},
      jobIncrease: 0,
      current: 0,
    }
  }
  updateRecords(props) {
    const { selectedLGAComparison, population, workingAgePop } = props

    if (selectedLGAComparison) {
      const lqs = selectedLGAComparison['lqs']
      const employment = selectedLGAComparison['emps']
      const multipliers = selectedLGAComparison['multipliers']

      let data = []
      let allLQs = []
      let { newEmployment } = this.state
      let existingJobs = 0
      Object.keys(lqs).forEach(industry => {
        lqs[industry] > 1 &&
          data.push({
            sector: industry,
            lq: lqs[industry],
            multiplier: multipliers[industry].toFixed(3),
            employment: employment[industry],
          })
        lqs[industry] < 1 &&
          allLQs.push({
            sector: industry,
            lq: lqs[industry],
            multiplier: multipliers[industry].toFixed(3),
            employment: employment[industry],
          })
        newEmployment[industry] = 0
        existingJobs += employment[industry]
      })
      data.sort((a, b) => a.lq > b.lq && -1) // Highest LQ to lowest
      this.setState({
        newEmployment,
        data,
        allLQs,
        newPop: population ? population[2]['y'] : this.state.newPop,
        existingJobs,
        workingAgePop,
      })
    }
  }
  componentDidMount() {
    this.updateRecords.bind(this)(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.updateRecords.bind(this)(nextProps)
  }

  render() {
    const {
      selectedLGAComparison,
      employmentActive,
      toggleClustersActive,
    } = this.props

    const { data, selectedIndustries, current, allLQs } = this.state

    if (!selectedLGAComparison) {
      return null
    }

    let multiplierdata = selectedIndustries

    selectedIndustries.forEach(item => {
      item['new'] = (
        <NewEmp
          {...this.state}
          record={item}
          updateState={data => {
            this.setState(data)
          }}
          ratios={selectedLGAComparison.ratios}
          setEmploymentResult={this.props.setEmploymentResult}
          setEmploymentCreated={this.props.setEmploymentCreated}
        />
      )
    })

    return (
      <Collapse
        bordered={false}
        defaultActiveKey={['0']}
        expandIcon={({ isActive }) => (
          <Icon
            style={{ color: '#fff' }}
            type="caret-right"
            rotate={isActive ? 90 : 0}
          />
        )}
        activeKey={employmentActive}
        onChange={toggleClustersActive}
      >
        <Panel
          header={<CollapseHeader label={'Create an employment scenario'} />}
          key="1"
        >
          <Container>
            <Row>
              <LeftPanel
                {...this.state}
                updateState={data => this.setState(data)}
                ratios={selectedLGAComparison.ratios}
                toggleClustersActive={toggleClustersActive}
              />
              <UnshadedPanel>
                <InfoText>
                  Modelling strategic employment creation for {this.props.lga}{' '}
                  <Tooltip title="Listed below are the employment sectors ranked from highest to lowest competitive advantage for the selected LGA. A breakdown of how many jobs are in this sector in the LGA presently. The employment multiplier effects of this sector are listed alongside. The tool allows you to add additional jobs in each sector, it will automatically keep a running tally applying the multiplier.">
                    <InfoIcon type={'info-circle'} />
                  </Tooltip>
                </InfoText>
                <StepsContainer size="small" current={current}>
                  <Step
                    key={0}
                    title={'Sector Selection'}
                    onClick={() => {
                      this.setState({ current: 0 })
                    }}
                    description={
                      <>
                        Pick the sectors with the highest impact for strategic 
                        job creation{' '}
                        <Tooltip title="Below is a list of the sectors in which the LGA has a competitive advantage – the multiplier gives an indication of the knock on effect one additional job in this sector will have in terms of employment (not necessarily in the LGA).">
                          <InfoIcon type={'info-circle'} />
                        </Tooltip>
                      </>
                    }
                  />
                  <Step
                    key={1}
                    title={'Add Jobs'}
                    onClick={() => {
                      this.setState({ current: 1 })
                    }}
                    description={'Employment multiplier effect'}
                  />
                </StepsContainer>

                {parseInt(current) === 0 && (
                  <SectorPicker
                    columns={[
                      { title: 'Sector', dataIndex: 'sector', key: 'sector' },
                      {
                        title: 'Location Quotient',
                        dataIndex: 'lq',
                        key: 'lq',
                      },
                      {
                        title: 'Multiplier',
                        dataIndex: 'multiplier',
                        key: 'multiplier',
                      },
                    ]}
                    data={data}
                    allLQs={allLQs}
                    that={this}
                    newEmployment={this.state.newEmployment}
                    selectedIndustries={this.state.selectedIndustries}
                  />
                )}
                {parseInt(current) === 1 && (
                  <Column>
                    <ResetScenario
                      onClick={() => {
                        this.setState({
                          selectedIndustries: [],
                          jobIncrease: 0,
                          newEmployment: {},
                          newEmploymentAdded: {},
                        })
                      }}
                    >
                      <Icon type={'reload'} />
                      <div>Reset scenario</div>
                    </ResetScenario>
                    <MultiplierCalc
                      columns={[
                        {
                          title: 'Sector',
                          dataIndex: 'sector',
                          key: 'sector',
                        },
                        {
                          title: 'Current',
                          dataIndex: 'employment',
                          key: 'employment',
                        },
                        {
                          title: 'Total Increase of Sector ($m)',
                          dataIndex: 'new',
                          key: 'new',
                        },
                        {
                          title: 'Multiplier',
                          dataIndex: 'multiplier',
                          key: 'multiplier',
                        },
                      ]}
                      data={multiplierdata}
                      setCurrent={() => this.setState({ current: 0 })}
                    />
                  </Column>
                )}

                <CenteredRow>
                  <span>Total increase in output demand ($m)</span>
                  <ColorWrapper>
                    {Object.values(this.state.newEmployment).reduce(
                      (prev, curr) => prev + curr,
                      0
                    )}
                  </ColorWrapper>
                </CenteredRow>

                <CenteredRow>
                  <span>
                    Total increase in jobs created by total additional output
                  </span>
                  <ColorWrapper>
                    {this.state.jobIncrease.toFixed(0)}
                  </ColorWrapper>
                </CenteredRow>
              </UnshadedPanel>
            </Row>
          </Container>
        </Panel>
      </Collapse>
    )
  }
}
