import { createSelector } from 'reselect'

export const activityTrendSelector = state => state.activityTrend.results

const selectedHubSelector = state => state.hubs.selectedHub

export const selectorActivityTrendData = createSelector(
    activityTrendSelector, selectedHubSelector, (data, area) => {

        var filteredData = data.activity.filter(d => d.hub === area.SmartRider_StopName);

        var cagList = [];
        var tixList = [];
        var typeList = [];

        var cagItemList = [];
        var tixItemList = [];
        var typeItemList = [];

        // Specify what goes where. 
        var cagTypes = {"Connect": "rgb(0, 0, 255)", "Attract": "rgb(0, 255, 0)", 
            "Generate": "rgb(255, 0, 0)"};
        var tixTypes = {"Standard": "rgb(128, 0, 0)", "Tertiary": "rgb(255, 165, 0)", 
            "School": "rgb(0, 255, 127)", "Concession": "rgb(70, 30, 180)"};
        var typeTypes = {"workDay": "rgb(25, 25, 112)", "schoolDay": "rgb(125, 206, 250)", 
            "short": "rgb(60, 179, 113)", "medium": "rgb(0, 0, 128)", 
            "transAMpeak": "rgb(255, 0, 0)", "transDay": "rgb(255, 165, 0)", 
            "transPMpeak": "rgb(255, 69, 0)", "transNight": "rgb(128, 128, 128)", 
            "sleep": "rgb(240 ,128, 128)", "<=3days": "rgb(165, 42, 42)", 
            ">3days": "rgb(128, 0, 0)", "open": "rgb(255, 255, 255)"};

        filteredData.forEach((each_row) => {
            var thisDict = {"series": null, "hub": null, "data": []};
            Object.entries(each_row).forEach(([key, value]) => {
                if (key === "type") {
                    thisDict["series"] = value;
                } else if (key === "hub") {
                    thisDict["hub"] = value;
                } else {
                    thisDict["data"].push({"x": key, "y": value});
                }
            });

            // Work out where we put it.
            if (Object.keys(cagTypes).includes(thisDict["series"])) {
                cagList.push(thisDict);
                thisDict["color"] = cagTypes[thisDict["series"]];
                cagItemList.push({"title": thisDict["series"], 
                    "color": cagTypes[thisDict["series"]]});
            } else if (Object.keys(tixTypes).includes(thisDict["series"])) {
                tixList.push(thisDict);
                thisDict["color"] = tixTypes[thisDict["series"]];
                tixItemList.push({"title": thisDict["series"], 
                    "color": tixTypes[thisDict["series"]]});
            } else if (Object.keys(typeTypes).includes(thisDict["series"])) {
                typeList.push(thisDict);
                thisDict["color"] = typeTypes[thisDict["series"]];
                typeItemList.push({"title": thisDict["series"], 
                    "color": typeTypes[thisDict["series"]]});
            } 

        });

        return {"cag": cagList, "tix": tixList, "type": typeList, 
            "cagItems": cagItemList, "tixItems": tixItemList, 
            "typeItems": typeItemList, "area": area.SmartRider_StopName};
    }
)

export default selectorActivityTrendData;