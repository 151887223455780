export const Map = {
  UPDATE_VIEWPORT: 'UPDATE_VIEWPORT',
  UPDATE_CENTER: 'UPDATE_CENTER',
  TOGGLE_LAYER: 'TOGGLE_LAYER',
  SET_MAP: "SET_MAP"
}
export const Accessibility = {
  SET_SA2_TRAVELTIME: 'SET_SA2_TRAVELTIME',
  SET_ACCESS_DATA: 'SET_ACCESS_DATA',
  SET_MODE_STAGING: 'SET_MODE_STAGING',
  SET_PERIOD_STAGING: 'SET_PERIOD_STAGING',
  SET_COMPARISON_DATA: 'SET_COMPARISON_DATA',
  SET_METRIC_STAGING: 'SET_METRIC_STAGING',
  SET_DIRECTION_STAGING: 'SET_DIRECTION_STAGING',
  SET_TIME_STAGING: 'SET_TIME_STAGING',
  UPDATE_PARAMS: 'UPDATE_PARAMS',
}

export const Context = {
  GET_METADATA: 'GET_METADATA',
  GET_METADATA_SUCCESS: 'GET_METADATA_SUCCESS',
  GET_METADATA_FAILURE: 'GET_METADATA_FAILURE',
  SELECT_LAYER: 'SELECT_LAYER',
  GET_ATTRIBUTE: 'GET_ATTRIBUTE',
  GET_ATTRIBUTE_SUCCESS: 'GET_ATTRIBUTE_SUCCESS',
  GET_ATTRIBUTE_FAILURE: 'GET_ATTRIBUTE_FAILURE',
  GET_GEOMETRY: 'GET_GEOMETRY',
  GET_GEOMETRY_SUCCESS: 'GET_GEOMETRY_SUCCESS',
  GET_GEOMETRY_FAILURE: 'GET_GEOMETRY_FAILURE',
  SET_VALUE: 'SET_VALUE',
  SET_STYLING: "SET_STYLING",
  SELECT_FEATURE: "SELECT_FEATURE"

}

export const Population = {
  GET_POPULATION: 'GET_POPULATION',
  GET_POPULATION_SUCCESS: 'GET_POPULATION_SUCCESS',
  GET_POPULATION_FAILURE: 'GET_POPULATION_FAILURE',
  
}

export const Loading = {
  SET_LOADING: 'SET_LOADING',
}

export const ModelContainer = {
  ADD_MODEL: 'ADD_MODEL',
  REMOVE_MODEL: 'REMOVE_MODEL',
  UPDATE_MODEL: 'UPDATE_MODEL',
  TOGGLE_SHOW: 'TOGGLE_SHOW',
}

export const Hubs = {
  SELECT_HUB: 'SELECT_HUB',
  ADD_STOPS: 'ADD_STOPS',
  LOAD_HUBS: 'LOAD_HUBS',
}

export const Search = {
  GET_RESULTS: 'GET_RESULTS',
  GET_RESULTS_SUCCESS: 'GET_RESULTS_SUCCESS',
  GET_RESULTS_FAILURE: 'GET_RESULTS_FAILURE',
}

export const Nodeplace = {
  SET_DISTANCE: 'SET_DISTANCE',
  GET_NODEPLACE: 'GET_NODEPLACE',
  GET_NODEPLACE_SUCCESS: 'GET_NODEPLACE_SUCCESS',
  GET_NODEPLACE_FAILURE: 'GET_NODEPLACE_FAILURE',
  GET_CLUSTERS: 'GET_CLUSTERS',
  GET_CLUSTERS_SUCCESS: 'GET_CLUSTERS_SUCCESS',
  GET_CLUSTERS_FAILURE: 'GET_CLUSTERS_FAILURE',
}

export const TravelSurvey = {
  GET_TRAVELSURVEY: 'GET_TRAVELSURVEY',
  GET_TRAVELSURVEY_SUCCESS: 'GET_TRAVELSURVEY_SUCCESS',
  GET_TRAVELSURVEY_FAILURE: 'GET_TRAVELSURVEY_FAILURE',
}

export const ActivityTrend = {
  GET_ACTTREND: 'GET_ACTTREND',
  GET_ACTTREND_SUCCESS: 'GET_ACTTREND_SUCCESS',
  GET_ACTTREND_FAILURE: 'GET_ACTTREND_FAILURE',
}

export const PassengerChurn = {
  GET_PASSCHURN: 'GET_PASSCHURN',
  GET_PASSCHURN_SUCCESS: 'GET_PASSCHURN_SUCCESS',
  GET_PASSCHURN_FAILURE: 'GET_PASSCHURN_FAILURE',
}

export const Behaviour = {
  GET_ACTIVITIES: 'GET_ACTIVITIES',
  GET_ACTIVITIES_SUCCESS: 'GET_ACTIVITIES_SUCCESS',
  GET_ACTIVITIES_FAILURE: 'GET_ACTIVITIES_FAILURE',
}

export const Employment = {
  GET_EMPLOYMENT: 'GET_EMPLOYMENT',
  GET_EMPLOYMENT_SUCCESS: 'GET_EMPLOYMENT_SUCCESS',
  GET_EMPLOYMENT_FAILURE: 'GET_EMPLOYMENT_FAILURE',
  SET_EMPLOYMENT_CONTROLS: 'SET_EMPLOYMENT_CONTROL'
}

export const About = {
  TOGGLE_ABOUT: 'TOGGLE_ABOUT',
}

export const Menu = {
  TOGGLE_MENU: 'TOGGLE_MENU',
}

export const SA2Layer = {
  GET_SA2_SUCCESS: 'GET_SA2_SUCCESS',
  GET_SA2_FAILURE: 'GET_SA2_FAILURE',
  SET_SA2_ATTRIBUTES: 'SET_SA2_ATTRIBUTES',
  SET_SA2_MODELNAME: 'SET_SA2_MODELNAME',
}

export const LGALayer = {
  GET_LGA_SUCCESS: 'GET_LGA_SUCCESS',
  GET_LGA_FAILURE: 'GET_LGA_FAILURE',
}

export const ScenarioPlanner = {
  SET_LGA: 'SET_LGA',
  SET_ORIGIN_CLUSTER: 'SET_ORIGIN_CLUSTER',
  SET_TARGET_CLUSTER: 'SET_TARGET_CLUSTER',
  SET_EMPLOYMENT_RESULT: 'SET_EMPLOYMENT_RESULT',
  SET_OCCUPANCY_RATE: 'SET_OCCUPANCY_RATE',
  GET_STRUCTUREPLANS: 'GET_STRUCTUREPLANS',
  GET_STRUCTUREPLANS_SUCCESS: 'GET_STRUCTUREPLANS_SUCCESS',
  GET_STRUCTUREPLANS_FAILURE: 'GET_STRUCTUREPLANS_FAILURE',
  SET_STRUCTUREPLAN_POP: 'SET_STRUCTUREPLAN_POP',
  SET_FUTURE_STN_POP: 'SET_FUTURE_STN_POP',
  GET_STN_BUFFERS: "GET_STN_BUFFERS",
  GET_STN_BUFFERS_SUCCESS: "GET_STN_BUFFERS_SUCCESS",
  GET_STN_BUFFERS_FAILURE: "GET_STN_BUFFERS_FAILURE",
  GET_WORKINGPOP: "GET_WORKINGPOP",
  GET_WORKINGPOP_SUCCESS: "GET_WORKINGPOP_SUCCESS",
  GET_WORKINGPOP_FAILURE: "GET_WORKINGPOP_FAILURE",
  TOGGLE_CLUSTERS_ACTIVE: "TOGGLE_CLUSTERS_ACTIVE",
  SET_EMPLOYMENT_CREATED: "SET_EMPLOYMENT_CREATED"
}

export const CostBenefitAnalysis  = {
  SET_ACTIVE_TRAVEL_PARAMS: 'SET_ACTIVE_TRAVEL_PARAMS',
  SET_YOUR_MOVE_PARAMS: 'SET_YOUR_MOVE_PARAMS'
}

export const MapToolBox  = {
  SET_MODE: 'SET_MODE'
}
